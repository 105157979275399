import { Button, Group } from '@mantine/core';
import React from 'react';

const NextButtonRow = ({ ...props }) => {
    const currentStep = parseInt(props.currStep);
    return (
        <Group position="right" sx={{ width: "100%" }} mt={16}>
            {props.hasBack && (
                <Button
                    onClick={() => {
                        props.setAccordionStep((currentStep - 1).toString());
                    }}
                    variant="subtle"
                >
                    Back
                </Button>
            )}
            {props.hasNext && (
                <Button
                    disabled={props.disabled}
                    onClick={() => {
                        props.nextFn
                            ? props.nextFn()
                            : props.setAccordionStep(
                                (currentStep + 1).toString()
                            );
                    }}
                >
                    Next
                </Button>
            )}
        </Group>
    );
};

export default NextButtonRow;