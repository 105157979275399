import {
    Button,
    Card,
    Center,
    Container,
    Divider,
    Drawer,
    Grid,
    Group,
    Indicator,
    Loader,
    Pagination,
    Skeleton,
    Stack,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { GET_CONFIGS, useGetConfigs } from "api/configAPI";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import {
    CONFIG_KEYS,
    USER_TYPE,
} from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bath, Bed, Edit, Filter, Home2, Search } from "tabler-icons-react";
import { QueryCache } from "@tanstack/react-query";
import { useDebouncedState, useDebouncedValue, useMediaQuery } from "@mantine/hooks";
import { useWallet } from "hooks/useWallet";
import { useListingAPI } from "api/useListingAPI";
import { useInsightsAPI } from "api/useInsightsAPI";
import { accountTypes } from "types/accountTypes";
import { AgentDetailModal } from "components/Modals/AgentDetailModal";
import { useProfileController } from "hooks/useProfile";
import { formatUnknownStateArea } from "helpers/utility";
import ListingFilters from "components/ListingFilters";
import LocationFilter from "components/LocationFilter";
import RequestTutorialModal from "components/Modals/RequestTutorialModal";
import PropRequestCardSkeleton from "components/LoadingStates/PropRequestCardSkeleton";

const defaultFilters = {
    propertyType: [],
    transactionType: [],
    tags: [],
    state: [],
    area: [],
    minSquareFt: "",
    maxSquareFt: "",
    minPrice: "",
    maxPrice: "",
    isUrgent: false,
    newProjectsOnly: false,
    subSalesOk: false,

};

const addUrl = (state: any) => {
    if (state.url) {
        delete state.url;
    }
    let andArr = [
        {
            status: {
                $eq: "ACTIVE",
            },
        }
    ] as any[];

    Object.entries(state).forEach(([key, value]) => {
        if (!!value) {
            switch (key) {
                case "subSalesOk": {
                    andArr.push({
                        subSalesOk: true,
                    });
                    break;
                }
                case "newProjectsOnly": {
                    andArr.push({
                        newProjectsOnly: true,
                    });
                    break;
                }
                case "isUrgent": {
                    andArr.push({
                        isUrgent: true,
                    });
                    break;
                }
                case "noOfBedrooms": {
                    // let price = urlObj[0].$and.
                    if (parseInt(value as string) === 1) {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBedrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "noOfBathrooms": {
                    if (value === 1) {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $gte: value,
                            },
                        });
                    } else {
                        andArr.push({
                            [`noOfBathrooms`]: {
                                $lte: value,
                            },
                        });
                    }
                    break;
                }
                case "maxSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`maxSquareFt`]: {
                            $lte: value,
                        },
                    });
                    break;
                }
                case "minSquareFt": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`minSquareFt`]: {
                            $gte: value,
                        },
                    });
                    break;
                }
                case "maxPrice": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`budgetMax`]: {
                            $lte: value,
                        },
                    });
                    break;
                }
                case "minPrice": {
                    // let price = urlObj[0].$and.
                    andArr.push({
                        [`budgetMin`]: {
                            $gte: value,
                        },
                    });
                    break;
                }
                case "propertyType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        andArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "transactionType": {
                    let _value = value as any[];
                    // let price = urlObj[0].$and.
                    if (_value.length > 0) {
                        andArr.push({
                            [`${key}`]: {
                                $in: value,
                            },
                        });
                    }
                    break;
                }
                case "tags": {
                    let tagArr = value as any[];
                    // console.log('tags value',value)
                    tagArr.forEach((tag: any) => {
                        andArr.push({
                            tags: tag,
                        });
                    });
                    break;
                }
                default:
                    andArr.push({
                        [`${key}`]: {
                            $in: value,
                        },
                    });
                    break;
            }
        }
    });
    return { $and: andArr };
};

function reducer(state: any, action: any) {
    try {
        let newState = {} as any;
        if (!!state.url) {
            delete state.url;
        }

        switch (action.type) {
            case "NEW_PROJECTS_ONLY_CHANGE": {
                newState = {
                    ...state,
                    newProjectsOnly: action.payload.value
                };
                break;
            }
            case "SUBSALES_OK_CHANGE": {
                newState = {
                    ...state,
                    subSalesOk: action.payload.value
                };
                break;
            }
            case "IS_URGENT_CHANGE": {
                newState = {
                    ...state,
                    isUrgent: action.payload.value
                };
                break;
            }
            case "LOCATION_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload.state,
                    area: action.payload.area,
                };
                break;
            }
            case "STATE_SELECT_CHANGE": {
                newState = {
                    ...state,
                    ["state"]: action.payload,
                };
                break;
            }
            case "AREA_SELECT_CHANGE": {
                newState = {
                    ...state,
                    area: action.payload,
                };
                break;
            }
            case "BUDGET_MIN_CHANGE": {
                newState = {
                    ...state,
                    minPrice: action.payload,
                };
                break;
            }
            case "BUDGET_MAX_CHANGE": {
                newState = {
                    ...state,
                    maxPrice: action.payload,
                };
                break;
            }
            case "PROPERTY_TYPE_CHANGE": {
                let statePropertyType = state.propertyType ?? [];
                if (!action.include) {
                    statePropertyType = statePropertyType.filter(
                        (f: any) => f !== action.payload
                    );
                } else {
                    statePropertyType = [...statePropertyType, action.payload];
                }

                newState = {
                    ...state,
                    propertyType: statePropertyType,
                };
                break;
            }
            case "PROPERTY_TRANSACTION_TYPE_CHANGE": {
                let stateTransactionType = state.transactionType ?? [];
                if (!action.include) {
                    stateTransactionType = stateTransactionType.filter(
                        (f: any) => f !== action.payload
                    );
                } else {
                    stateTransactionType = [
                        ...stateTransactionType,
                        action.payload,
                    ];
                }

                newState = {
                    ...state,
                    transactionType: stateTransactionType,
                };
                break;
            }
            case "BATHROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBathrooms: action.payload,
                };
                break;
            }
            case "BEDROOM_CHANGE": {
                newState = {
                    ...state,
                    noOfBedrooms: action.payload,
                };
                break;
            }
            case "MIN_SQFT_CHANGE": {
                newState = {
                    ...state,
                    minSquareFt: action.payload,
                };
                break;
            }
            case "MAX_SQFT_CHANGE": {
                newState = {
                    ...state,
                    maxSquareFt: action.payload,
                };
                break;
            }
            case "TAG_CHANGE": {
                let selectArr = state.tags ?? [];
                if (!action.include) {
                    selectArr = state.tags.filter(
                        (f: any) => f.value !== action.payload
                    );

                    newState = {
                        ...state,
                        tags: selectArr,
                    };
                    break;
                }

                newState = {
                    ...state,
                    tags: [
                        ...state.tags,
                        {
                            value: action.payload,
                        },
                    ],
                };
                break;
            }
            case "RESET": {
                newState = defaultFilters;
                break;
            }
        }

        return newState;
    } catch (e) {
        throw Error("Unknown action: " + action.type);
    }
}

export default function SearchRequestsBrowse() {
    const [state, dispatch] = useReducer(reducer, defaultFilters);

    const theme = useMantineTheme();
    const isTabletAndAbove = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    const navigate = useNavigate();
    const location = useLocation()
    const { useGetSearchPropRequests, useGetPropRequestInsights, useGetTags } =
        useListingAPI();
    const { useGetRequestInsights } = useInsightsAPI()
    const {
        data: requestInsightData,
        isLoading: requestInsightIsLoading,
        error: requestInsightError,
    } = useGetRequestInsights();

    const { user } = useAuth(Permission.USER_ONLY);
    const { profileData, refetchProfile, isPro, isBasic } = useProfileController();
    const { walletData, walletIsLoading, walletError } = useWallet(
        user.userType === USER_TYPE.AGENT ? user.id.toString() : null
    );

    const [filterOpen, setFilterOpen] = useState(false)

    const [agentDetailOpen, setAgentDetailOpen] = useState(false)
    const [agentTutorialOpen, setAgentTutorialOpen] = useState(false)
    const [isTutorialFromHelp, setIsTutorialFromHelp] = useState(false)

    const [locationFilters, setLocationFilters] = useState<any>({
        state: [],
        area: [],
    });
    const [filters, setFilters] = useState({});
    const [debouncedState] = useDebouncedValue(state, 500);

    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (!!profileData) {
            const sessionSkip = sessionStorage.getItem("skipAgentDetail")

            let stateArr = [] as string[]
            let areaArr = [] as string[]

            if (!profileData.requestTutorialComplete) {
                // setIsTutorialFromHelp(false)
                // setAgentTutorialOpen(true)
            } else {
                if (!!profileData?.localityState) {
                    // stateArr = [profileData.localityState]

                    // if (!!profileData.localityArea) {
                    //     areaArr = [profileData.localityArea]
                    // }
                    // setLocationFilters({
                    //     state: stateArr,
                    //     area: areaArr
                    // })
                } else {
                    setAgentDetailOpen(!profileData?.localityState && !sessionSkip)
                }
            }
        }

    }, [profileData])

    const handleCloseTutorialOpen = useCallback(() => {
        if (!!profileData) {
            setAgentTutorialOpen(false)
            if (!isTutorialFromHelp) {
                refetchProfile()
            }
            if (!profileData?.localityState) {
                setAgentDetailOpen(true)
            }
        }
    }, [profileData])

    const searchByLocality = useCallback(() => {
        if (!!profileData) {
            let stateArr = [] as string[]
            let areaArr = [] as string[]

            if (!!profileData?.localityState) {
                stateArr = [profileData.localityState]

                if (!!profileData.localityArea) {
                    areaArr = [profileData.localityArea]
                }
                dispatch({
                    type: "LOCATION_SELECT_CHANGE",
                    payload: {
                        state: stateArr,
                        area: areaArr
                    },
                });
            }
        }
    }, [profileData])

    const handleAgentDetailClose = () => {
        sessionStorage.removeItem("skipAgentDetail")

        setAgentDetailOpen(false)
        refetchProfile()
    }

    useEffect(() => {
        if (location?.state?.fromRegister) {
            if (user?.userType === accountTypes.AGENT) {
                setAgentDetailOpen(true);
            }
            window.history.replaceState({}, document.title);
        }
    }, [user, location?.state]);

    const generateUrlObj = useCallback(
        (state: any) => {
            // console.log("addurl", addUrl(state));
            return addUrl(state);
        },
        [state]
    );

    const { data, isLoading, error, refetch } = useGetSearchPropRequests({
        filters,
        pagination: {
            page: currentPage,
            pageSize: 10
        }
    });

    const results = useMemo(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            setTotal(result.meta.pagination.pageCount)
            setCurrentPage(result.meta.pagination.page)
            console.log("filters",filters)
            return result.data
        }

        return []
    }, [data, isLoading, filters])

    const {
        data: tagsData,
        isLoading: tagsIsLoading,
        error: tagsError,
    } = useGetTags();

    const {
        data: insightsData,
        isLoading: insightsIsLoading,
        error: insightsError,
    } = useGetPropRequestInsights();

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    useEffect(() => {
        // if (!!insightsData && !insightsIsLoading) {
        //     setBudgetFilters([insightsData.rangeMin, insightsData.rangeMax]);
        // }
    }, [insightsData]);

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    const areasFromStates = useMemo(() => {
        const selectedStates = locationFilters.state;
        const areas = statesAndAreas
            .filter((state: any) => selectedStates.includes(state.state))
            .map((s: any) => s.areas)
            .flat()
            .map((s: any) => ({
                value: s,
                label: s,
            }));
        return areas;
    }, [locationFilters]);

    const searchArray = useMemo(() => {
        if (statesAndAreas.length < 0) {
            return []
        }

        const searchList = [] as any[]

        statesAndAreas.forEach((element: any) => {
            searchList.push({
                value: `state-${element.state}`,
                label: element.state,
            })

            element.areas.forEach((area: string) => {
                searchList.push({
                    value: `area-${area}`,
                    label: area,
                })
            })
        });

        return searchList
    }, [statesAndAreas])

    const filterCount = useMemo(() => {
        let count = 0;

        Object.entries(state).forEach(([k, s]) => {
            if (k === "tags") {
                count += state.tags.length || 0;
                return;
            }

            if (!s) {
                return;
            }

            if (typeof s === "object") {
                if (Object.keys(s).length === 0) {
                    return;
                } else {
                    count += Object.keys(s).length;
                }
                return;
            }

            count += 1;
            return;
        });
        return count;
    }, [state]);

    const handleSearch = (isReset?: boolean) => {
        let urlState = state
        if (!!isReset) {
            urlState = defaultFilters
        }

        setFilters(generateUrlObj(urlState));
    };

    useEffect(() => {
        if (Object.keys(debouncedState).length > 0 && !!isTabletAndAbove) {
            handleSearch();
        }
    }, [debouncedState, isTabletAndAbove]);

    useEffect(() => {
        handleSearch();
    }, [state.state, state.area]);

    const handleClearFilters = () => {
        dispatch({
            type: "RESET",
        });
        handleSearch(true);
    }

    const handleAgentDetailSkip = () => {
        sessionStorage.setItem("skipAgentDetail", "true")
        setAgentDetailOpen(false)
    }

    return (
        <>
            <Drawer
                opened={filterOpen}
                onClose={() => {
                    setFilterOpen(false);
                    handleSearch();
                }}
                position="right"
            >
                <ListingFilters
                    dispatch={dispatch}
                    state={state}
                    handleClearFilters={handleClearFilters}
                    onClose={() => setFilterOpen(false)}
                />
            </Drawer>
            <Container size="1200px" py={32} px={16}>
                <Stack spacing={24}>
                    <Group position="apart" >
                        <Stack spacing={8}>
                            <Text fz={32} fw={600}>
                                Browse Requests {!!requestInsightData && <Text component="span" size="sm">({requestInsightData.totalPropertyRequests} total)</Text>}
                            </Text>
                            {/* <Button
                                onClick={() => {
                                    setIsTutorialFromHelp(true)
                                    setAgentTutorialOpen(true)
                                }}
                            >
                                Tutorial
                            </Button> */}
                        </Stack>
                    </Group>
                    <Divider my={8} />
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSearch();
                        }}
                    >
                        <Grid>
                            <Grid.Col sm={12}>
                                <Grid>
                                    <Grid.Col sm={12}>
                                        <LocationFilter dispatch={dispatch} state={state} />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                            {!isTabletAndAbove && (
                                <Grid.Col sm={12}>
                                    <Group
                                        sx={{
                                            flexWrap: "nowrap",
                                            alignItems: "flex-start",
                                            width: "100%",
                                        }}
                                        spacing={4}
                                    >
                                        <Indicator
                                            label={filterCount}
                                            disabled={filterCount === 0}
                                            size={16}
                                            sx={{ flex: 1 }}
                                            position="top-end"
                                        >
                                            <Button
                                                variant="outline"
                                                // leftIcon={}
                                                onClick={() => setFilterOpen(true)}
                                                px={8}
                                                sx={{ width: '100%' }}
                                            >
                                                <Group spacing={0}>
                                                    <Text>Filters</Text>
                                                    <Filter />
                                                </Group>
                                            </Button>
                                        </Indicator>
                                        {filterCount > 0 && (
                                            <Button
                                                variant="subtle"
                                                onClick={() => handleClearFilters()}
                                            >
                                                Clear Filters
                                            </Button>
                                        )}
                                    </Group>
                                </Grid.Col>
                            )}
                        </Grid>
                    </form>
                    <Grid>
                        {!!isTabletAndAbove && (
                            <Grid.Col xs={0} sm={4}>
                                <Stack>
                                    {!!profileData?.localityArea || !!profileData?.localityState && (
                                        <Button onClick={() => searchByLocality()}>Filter my locality only ({formatUnknownStateArea(profileData?.localityState, profileData?.localityArea)})</Button>
                                    )}
                                    <ListingFilters
                                        dispatch={dispatch}
                                        state={state}
                                        handleClearFilters={handleClearFilters}
                                    />
                                </Stack>
                            </Grid.Col>
                        )}
                        <Grid.Col xs={12} sm={8}>
                            <Stack spacing={16}>
                                {!!isLoading && (
                                    <>
                                        <PropRequestCardSkeleton />
                                        <PropRequestCardSkeleton />
                                        <PropRequestCardSkeleton />
                                    </>
                                )}

                                {!!results &&
                                    results.length > 0 &&
                                    results.map((propReq: any) => (
                                        <PropRequestCard data={propReq} forAgents hasPermissions={isPro || isBasic} />
                                    ))}
                                {!!results && results.length === 0 && (
                                    <Stack align="center" spacing={8} py={32}>
                                        <Home2 size={32} />
                                        <Text fz={24}>No Data</Text>
                                    </Stack>
                                )}
                                <Group sx={{ width: '100%', justifyContent: 'center' }}>
                                    <Pagination total={total} onChange={setCurrentPage} />

                                </Group>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Stack>
            </Container>
        </>
    );
}
