import { Checkbox, Flex, Grid, Group, Select, Stack, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React, { useMemo } from 'react';

const TagForm = ({ setTagForm, tagForm }: any) => {

    const tagsOptionOnChange = (
        title: string,
        value: string | null | boolean
    ) => {
        if (value === null) {
            return;
        }

        const editing = tagForm[`${title}`];
        setTagForm((prev: any) => ({
            ...tagForm,
            [`${title}`]: {
                ...editing,
                value,
            },
        }));
    };

    const generateInput = (tagSettings: any) => {
        let label = tagSettings.tooltip ? (
            <Tooltip label={tagSettings.tooltip}>
                <Group spacing={2} sx={{ alignItems: "center" }}>
                    <IconInfoCircle size={14} />
                    <Text>{tagSettings.title}</Text>
                </Group>
            </Tooltip>
        ) : (
            <Text>{tagSettings.title}</Text>
        );
        switch (tagSettings.values.type) {
            case "select":
                return (
                    <Select
                        label={label}
                        placeholder="Pick one"
                        data={tagSettings.values.options}
                        value={tagSettings.value}
                        onChange={(v) =>
                            tagsOptionOnChange(tagSettings.title, v)
                        }
                    />
                );
            case "boolean":
                return (
                    <Flex sx={{ flexDirection: "row", gap: 8 }}>
                        <Checkbox
                            checked={tagSettings.value}
                            onChange={(event) =>
                                tagsOptionOnChange(
                                    tagSettings.title,
                                    event.currentTarget.checked
                                )
                            }
                            label={label}
                        />
                    </Flex>
                );
        }
    };

    const tagInputs = useMemo(() => {
        const selects = [] as any[];
        const booleans = [] as any[];

        if (!!tagForm) {
            Object.values(tagForm).forEach((tag: any) => {
                if (tag.values.type === "boolean") {
                    booleans.push(tag);
                }

                if (tag.values.type === "select") {
                    selects.push(tag);
                }
            });
        }

        return {
            selects,
            booleans
        }

    }, [tagForm])


    return (
        <Stack>
            {tagInputs.selects.map((tag: any) => (
                <React.Fragment key={`tag-${tag.title}`}>
                    {generateInput(tag)}
                </React.Fragment>
            ))}
            <Grid>
                {tagInputs.booleans.map((tag: any) => (
                    <Grid.Col md={3} sm={6} xs={6} key={`boolean-tag-${tag.title}`}>
                        {generateInput(tag)}
                    </Grid.Col>
                ))}
            </Grid>
        </Stack>
    );
};

export default TagForm;