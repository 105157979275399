import { Group, Text } from '@mantine/core';
import React from 'react';

const EditSectionHeader = ({ ...props }) => {
    return (
        <Group position="apart">
            <Text fz={18} fw={700}>
                {props.title}
            </Text>
            <Text
                fz={14}
                td="underline"
                sx={{ cursor: "pointer" }}
                onClick={props.onClick}
            >
                Edit
            </Text>
        </Group>
    );
};

export default EditSectionHeader;