import { notifications } from "@mantine/notifications";
import { useAgentRequestAPI } from "api/useAgentRequestApi";
import { getTagDisplayText } from "helpers/utility";
import { useCreateRequest } from "pages/PropRequestForm/hooks/useCreateRequest";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const usePostLoginFunctions = () => {
    const { createRequest, createRequestIsLoading } = useCreateRequest();
    const { useCreateAgentRequest } = useAgentRequestAPI();
    const createAgentRequestMutation = useCreateAgentRequest();
    const navigate = useNavigate();

    const createPropRequest = async (user: any, data: any) => {
        try {
            console.log("createRequest", data);
            await createRequest(data.form, data.tags, user.id);
            sessionStorage.removeItem("pre_login_draft");
        } catch (e) {
            notifications.show({
                title: "Error creating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    };

    const createAgentRequest = async (user: any, data: any) => {
        // const draft = sessionStorage.getItem("pre_login_draft");
        // if(!!draft){
        //     sessionStorage.setItem("continue_agent_request", draft)
        // }
        // navigate("/owner-listings/create")
        // window.location.href =

        try {
            let tagArr = [];

            for (const tag of Object.values(data.tagForm)) {
                const tagValue = tag as any;
                let tagObj = {
                    tagId: tagValue.id,
                } as any;

                if (tagValue.values.type === "boolean" && !!tagValue.value) {
                    tagObj["value"] = tagValue.title;
                }

                if (tagValue.values.type === "select") {
                    tagObj["value"] = getTagDisplayText(tag, tagValue.value);
                }

                if (!!tagObj.value) {
                    tagArr.push(tagObj);
                }
            }

            createAgentRequestMutation.mutate(
                {
                    ...data.form,
                    lister: user.id,
                    images: data.filesArr.map((a: any) => a.id),
                    tags: tagArr
                },
                {
                    onSuccess: () => {
                        notifications.show({
                            title: "Success!",
                            message: "Successfully created owner listing",
                            autoClose: 2000,
                        });
                        sessionStorage.removeItem("pre_login_draft");
                    },
                    onError: () => {
                        notifications.show({
                            title: "Error creating owner listing",
                            message:
                                "If this issue persist, please contact our support",
                            autoClose: 2000,
                        });
                    },
                }
            );
        } catch (e) {
            notifications.show({
                title: "Error creating property request",
                message: "If this issue persist, please contact our support",
                autoClose: 2000,
            });
        }
    };

    return {
        createPropRequest,
        createAgentRequest,
        createRequestIsLoading,
        createAgentRequestIsLoading: createAgentRequestMutation.isLoading,
    };
};

export default usePostLoginFunctions;
