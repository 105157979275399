import { AppShell, Container, Stack } from "@mantine/core";
import { Outlet, useMatches, useParams } from "react-router-dom";
import CustomHeader from "../Header/Header";
import CustomFooter from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { defaultQueryFn } from "api/defaultQueryFn";
import { UserProvider } from "context/user/userProvider";
import { ThemeProvider } from "ThemeProvider";
import { useEffect } from "react";
import PropmothFree from "components/Banners/PropmothFree";

const queryClient = new QueryClient();

export default function Layout(props: any) {
    const location = useLocation();

    const { id } = useParams();
    const noBanner = ["/", "/login", "/register/agent", "/register/agent", "/agents", "/agent-promo"]
    const noHeader = ["/requests/create", "/owner-listings/create", `/owner-listings/edit/${id}`, `/requests/edit/${id}`, "/", "/agents", "/agent-promo", "/profile", "/owners"];
    const noFooter = ["/requests/create", "/owner-listings/create", `/owner-listings/edit/${id}`, `/requests/edit/${id}`, "/chats", "/profile", `/requests/${id}/offer`];

    // useEffect(() => {
    //     // execute on location change
    //     props.ReactPixel.pageView()
    // }, [location]);
    return (
        <UserProvider>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <AppShell
                        padding="md"
                        header={
                            !noHeader.includes(location.pathname) ? (
                                <CustomHeader />
                            ) : (
                                <></>
                            )
                        }
                        footer={
                            !noFooter.includes(location.pathname) ? (
                                <CustomFooter />
                            ) : (
                                <></>
                            )
                        }
                        styles={(theme) => ({
                            main: {
                                backgroundColor:
                                    theme.colorScheme === "dark"
                                        ? theme.colors.dark[8]
                                        : theme.colors.gray[0],
                                position: "relative",
                                // minHeight: "calc(100vh - 88px) !important",
                                padding: 0,
                                // overflowX: "hidden",
                                width: '100vw'
                            },
                        })}
                    >
                        {/* {!noBanner.includes(location.pathname) && (
                            <Container size="1200px" p={16}>
                                <PropmothFree />
                            </Container>
                        )} */}
                        <Outlet />
                    </AppShell>
                </ThemeProvider>
            </QueryClientProvider>
        </UserProvider>
    );
}
