import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Permission, useAuth } from "../../hooks/useAuth";
import {
    ActionIcon,
    Box,
    Button,
    Group,
    Text,
    Container,
    Stack,
    Avatar,
    Menu,
    HoverCard,
    Divider,
    Image,
    Drawer,
    useMantineTheme,
    Popover,
    UnstyledButton,
    Center,
    Indicator,
} from "@mantine/core";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { Bell, ChevronRight, Menu2, Messages } from "tabler-icons-react";
import { DATE_DISPLAYS, USER_TYPE } from "helpers/constants";
import { useProfileController } from "hooks/useProfile";
import { accountTypes } from "types/accountTypes";
import { AgentDetail, ListerDetail } from "types/userTypes";
import { UserDetailModal } from "components/Modals/UserDetailModal";
import { useMediaQuery } from "@mantine/hooks";
import moment from "moment";
import { Notification } from "pages/Notification";
import { useQuery } from "react-query";
import { useGetNotificationCount, useGetNotifications } from "api/userAPI";
import { useGetConfigs } from "api/configAPI";
import { IconCoin } from "@tabler/icons-react";
import { useWallet } from "hooks/useWallet";
import usePostLoginFunctions from "hooks/usePostLoginFunctions";
import ContinueDraftModal from "components/Modals/ContinueDraftModal";

enum notificationViews {
    PAGE,
    POPOVER,
}

export default function CustomHeader({ isLanding, noRightSection, agentLanding }: { isLanding?: boolean, noRightSection?: boolean, agentLanding?: boolean }) {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout, profileData } = useAuth(Permission.ANYONE);

    // const { walletData, walletIsLoading, walletError } = useWallet(
    //     user.userType === USER_TYPE.AGENT ? user.id.toString() : null
    // );

    const showMobileHeader = useMediaQuery(
        `(max-width: ${theme.breakpoints.sm})`
    );
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)
        ? notificationViews.PAGE
        : notificationViews.POPOVER;
    const [drawerOpen, setDrawerOpen] = useState(false);

    const {
        data: notificationCountData,
        error: notificationCountError,
        isLoading: notificationCountIsLoading,
        refetch: refetchCount,
    } = useGetNotificationCount({
        id: user.id ?? null,
        jwt: user.jwt ?? null
    });

    const {
        data: notificationData,
        error: notificationError,
        isLoading: notificationIsLoading,
        refetch: refetchNoti,
    } = useGetNotifications({
        id: user.id ?? null,
        jwt: user.jwt ?? null
    });

    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    // const hideHeader = location.pathname.includes("register")
    //     || location.pathname.includes('login');


    const links = useMemo(() => {
        let linkArr = [
            {
                link: "/articles",
                text: "News",
            },
            {
                link: "/our-story",
                text: "Our Story",
            }
            // {
            //     link: "/listings",
            //     text: "Browse Properties",
            // },
            // {
            //     link: "/agent-requests/create",
            //     text: "Sell Property",
            // }
        ];

        if (!!user.jwt) {
            if (user.userType === USER_TYPE.LISTER) {
                // const listerLinks = [
                // ];
                // linkArr.push(...listerLinks);
                // linkArr.push({
                //     link: "/agent-requests/create",
                //     text: "Sell Property",
                // })
            }

            if (user.userType === USER_TYPE.AGENT) {
                const agentLinks = [
                    // {
                    //     link: "/feedback",
                    //     text: "Feedback",
                    // },
                    // {
                    //     link: "/requests/search",
                    //     text: "Browse Requests",
                    // },
                    {
                        link: "/masterlist",
                        text: "Propmoth Masterlist"
                    }
                    // {
                    //     link: "/tokens/purchase",
                    //     text: "Buy Tokens",
                    // },
                ];

                // linkArr.push(...agentLinks);
            }
        }

        return linkArr;
    }, [user]);

    const avatarUrl = useMemo(() => {
        if (user && profileData) {
            if (user.userType === accountTypes.AGENT) {
                const profile = profileData as AgentDetail;
                return profile.agent?.avatar?.formats?.thumbnail?.url;
            } else {
                const profile = profileData as ListerDetail;
                return profile.user?.avatar?.formats?.thumbnail?.url;
            }
        }
        return "";
    }, [user, profileData]);



    const closeAndRedirect = (route: string) => {
        setDrawerOpen(false);
        navigate(route);
    };

    const notificationCount = useMemo(() => {
        return notificationCountData?.length ?? 0;
    }, [notificationCountData]);

    const setConfig = (configData: any) => {
        sessionStorage.setItem("propmoth_config", JSON.stringify(configData));

        const timeout = moment().add(1, "hours").format("hh:mm:ss DD/MM/YYYY");
        sessionStorage.setItem("propmoth_config_timeout", timeout);
    };

    // useEffect(() => {
    //     const config = sessionStorage.getItem("propmoth_config");

    //     if (!config && !!configData) {
    //         setConfig(configData);
    //     } else {
    //         const timeout = sessionStorage.getItem("propmoth_config_timeout");

    //         if (moment(timeout).isAfter(moment())) {
    //             refetchConfig().then((data) => {
    //                 setConfig(configData);
    //             });
    //         }
    //     }
    // }, [configData]);

    // const formatTokenValue = useCallback(() => {
    //     if (!!walletData?.amount) {
    //         return walletData.amount > 99 ? "99+" : walletData.amount
    //     }

    //     return "0"
    // }, [walletData])

    const logoRedirect = useCallback(() => {
        if (!!user.jwt) {
            navigate("/profile")
            return
        }

        navigate("/")
    }, [user])

    const chatRedirect = useMemo(() => {
        if (!!user.jwt ) {
            if(user.userType === USER_TYPE.AGENT){
                return "/profile?tab=chat"
            }
            if(user.userType === USER_TYPE.LISTER){
                return "/chats"
            }
        }

        return "/login"
    },[user])

    return (
        <Box sx={{ background: agentLanding ? 'rgba(0,0,0, 0.4)' : "transparent" }}>
            <Container size="1200px" py={20} px={24}>
                <Group position="apart">
                    <Group spacing={8} sx={{ alignItems: "stretch" }}>
                        <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => logoRedirect()}
                        >
                            {!isXs ? (
                                <Image
                                    src={"/propmoth-white-circle.png"}
                                    width={32}
                                    height={32}
                                />
                            ) : (
                                <>
                                    {isLanding ? (
                                        <Image
                                            src={"/logo-white.svg"}
                                            alt={"propmoth logo"}
                                            width={148}
                                            height={48}
                                        />
                                    ) : (
                                        <Image
                                            src={"/logo.svg"}
                                            alt={"propmoth logo"}
                                            width={148}
                                            height={48}
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                        {!showMobileHeader && !isLanding && (
                            <Group spacing={0} sx={{ alignItems: "stretch" }}>
                                {location.pathname === "/" && (
                                    <Button
                                        variant="none"
                                        size="xs"
                                        px={8}
                                        styles={{
                                            root: {
                                                marginTop: 3,
                                                height: "100% !important",
                                            },
                                        }}
                                        onClick={() => navigate("/#why-we-exist")}
                                    >
                                        <Text
                                            color={
                                                isLanding
                                                    ? "white"
                                                    : "primary"
                                            }
                                            sx={(theme) => ({
                                                // height: '100%',
                                                [theme.fn.largerThan("md")]:
                                                {
                                                    fontSize: "14px",
                                                },
                                            })}
                                        >
                                            Why we exist
                                        </Text>
                                    </Button>
                                )}
                                {links.map((link) => (
                                    <Button
                                        key={link.text}
                                        variant="none"
                                        onClick={() => navigate(link.link)}
                                        size="xs"
                                        px={8}
                                        styles={{
                                            root: {
                                                marginTop: 3,
                                                height: "100% !important",
                                            },
                                        }}
                                    >
                                        <Text
                                            color={
                                                isLanding ? "white" : "primary"
                                            }
                                            sx={(theme) => ({
                                                [theme.fn.largerThan("md")]: {
                                                    fontSize: "14px",
                                                },
                                            })}
                                        >
                                            {link.text}
                                        </Text>
                                    </Button>
                                ))}
                            </Group>
                        )}
                    </Group>
                    {!noRightSection && (
                        <>
                            {!showMobileHeader && (
                                <Group spacing={8}>
                                    {!!user.jwt ? (
                                        <>
                                            <HoverCard>
                                                <HoverCard.Target>
                                                    <Group
                                                        spacing={4}
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                            navigate("/profile")
                                                        }
                                                    >
                                                        <Avatar
                                                            radius={"50%"}
                                                            src={avatarUrl}
                                                        />
                                                    </Group>
                                                </HoverCard.Target>
                                                <HoverCard.Dropdown
                                                    p={0}
                                                    sx={{ minWidth: "15vw" }}
                                                >
                                                    <Stack spacing={8}>
                                                        <Stack
                                                            spacing={4}
                                                            align="center"
                                                            p={8}
                                                        >
                                                            <Avatar
                                                                radius="sm"
                                                                src={avatarUrl}
                                                            />
                                                            <Stack spacing={2}>
                                                                <Text
                                                                    sx={{
                                                                        lineHeight:
                                                                            "12px",
                                                                    }}
                                                                    fz={12}
                                                                    fw={700}
                                                                    align="center"
                                                                >
                                                                    {user.fullname}
                                                                </Text>
                                                            </Stack>
                                                        </Stack>
                                                        <Stack
                                                            p={8}
                                                            sx={{
                                                                borderTop:
                                                                    "1px solid #ddd",
                                                            }}
                                                        >
                                                            <Text
                                                                sx={{
                                                                    cursor: "pointer",
                                                                }}
                                                                align="left"
                                                                color="red.7"
                                                                onClick={() => logout()}
                                                                fz={12}
                                                            >
                                                                Logout
                                                            </Text>
                                                        </Stack>
                                                    </Stack>
                                                </HoverCard.Dropdown>
                                            </HoverCard>

                                            <Popover width={400}>
                                                <Popover.Target>
                                                    <Indicator
                                                        disabled={
                                                            !notificationCountData ||
                                                            notificationCountData?.length ===
                                                            0
                                                        }
                                                        label={
                                                            notificationCountData?.length ??
                                                            0
                                                        }
                                                        size={16}
                                                    >
                                                        <ActionIcon
                                                            radius="xl"
                                                            variant="subtle"
                                                        // sx={
                                                        //     isLanding
                                                        //         ? {
                                                        //             borderColor:
                                                        //                 "#fff",
                                                        //         }
                                                        //         : {}
                                                        // }
                                                        >
                                                            <Bell
                                                                size={24}
                                                                color={
                                                                    isLanding
                                                                        ? "white"
                                                                        : "black"
                                                                }
                                                            />
                                                        </ActionIcon>
                                                    </Indicator>
                                                </Popover.Target>
                                                <Popover.Dropdown
                                                    sx={{
                                                        minHeight: "33vh",
                                                        maxHeight: "80vh",
                                                        overflowY: "scroll",
                                                        overscrollBehaviorY: "contain",
                                                        zIndex: 1000
                                                    }}
                                                >
                                                    <Notification
                                                        notificationData={
                                                            notificationData
                                                        }
                                                        newCount={notificationCount}
                                                        refetch={() => {
                                                            refetchCount();
                                                            refetchNoti();
                                                        }}

                                                    // onNotiClick={() => }
                                                    />
                                                </Popover.Dropdown>
                                            </Popover>

                                            <ActionIcon
                                                radius="xl"
                                                variant="subtle"
                                                onClick={() => {
                                                    navigate(chatRedirect);
                                                }}
                                            // sx={
                                            //     isLanding
                                            //         ? { borderColor: "#fff" }
                                            //         : {}
                                            // }
                                            >
                                                <Messages
                                                    size={24}
                                                    color={
                                                        isLanding ? "white" : "black"
                                                    }
                                                />
                                            </ActionIcon>
                                            {/* {user.userType === USER_TYPE.AGENT && (
                                                <Button
                                                    variant="subtle"
                                                    compact
                                                    onClick={() =>
                                                        navigate("/tokens/purchase")
                                                    }
                                                >
                                                    <Image
                                                        src={"/token.png"}
                                                        height={24}
                                                        width={24}
                                                        style={{ marginRight: "4px" }}
                                                    />
                                                    <Text
                                                        mr={4}
                                                        size={20}
                                                        weight={500}
                                                        sx={{ lineHeight: "30px" }}
                                                    >
                                                        {formatTokenValue()}
                                                    </Text>
                                                </Button>
                                            )} */}
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                variant="outline"
                                                onClick={() => navigate("/login")}
                                                sx={{
                                                    background: agentLanding ? "white" : "transparent"
                                                }}
                                            >
                                                Log in
                                            </Button>
                                            <Button
                                                variant="filled"
                                                onClick={() => navigate("/register")}
                                            >
                                                Register
                                            </Button>
                                        </>
                                    )}
                                </Group>
                            )}
                            {!!showMobileHeader && (
                                <Group>
                                    {!!user.jwt && (
                                        <>
                                            <Indicator
                                                disabled={
                                                    !notificationCountData ||
                                                    notificationCountData?.length === 0
                                                }
                                                label={notificationCount}
                                                size={16}
                                            >
                                                <ActionIcon
                                                    radius="xl"
                                                    variant="subtle"
                                                    onClick={() =>
                                                        navigate("/notifications")
                                                    }
                                                // sx={
                                                //     isLanding
                                                //         ? {
                                                //             borderColor: "#fff",
                                                //         }
                                                //         : {}
                                                // }
                                                >
                                                    <Bell
                                                        size={24}
                                                        color={
                                                            isLanding
                                                                ? "white"
                                                                : "black"
                                                        }
                                                    />
                                                </ActionIcon>
                                            </Indicator>
                                            <ActionIcon
                                                radius="xl"
                                                variant="subtle"
                                                onClick={() => {
                                                    navigate(chatRedirect);
                                                }}
                                            // sx={
                                            //     isLanding
                                            //         ? {
                                            //             borderColor: "#fff",
                                            //         }
                                            //         : {}
                                            // }
                                            >
                                                <Messages
                                                    size={24}
                                                    color={
                                                        isLanding ? "white" : "black"
                                                    }
                                                />
                                            </ActionIcon>
                                        </>
                                    )}
                                    <ActionIcon onClick={() => setDrawerOpen(true)}>
                                        <Menu2 color={isLanding ? "white" : "black"} />
                                    </ActionIcon>
                                </Group>
                            )}
                        </>
                    )}
                </Group>
            </Container>
            <Drawer
                opened={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                title={<Image src="/logo.png" width={142} height={32} />}
                styles={{
                    body: {
                        height: "calc(100% - 64px)",
                    },
                }}
            >
                <Stack spacing={8} sx={{ height: "100%" }}>
                    <Stack sx={{ flex: 1 }}>
                        {!!user.jwt ? (
                            <>
                                <Stack
                                    spacing={4}
                                    align="center"
                                    p={8}
                                    onClick={() => {
                                        closeAndRedirect("/profile");
                                    }}
                                    mt={16}
                                >
                                    <Avatar radius="50%" src={avatarUrl} />
                                    <Stack spacing={2}>
                                        <Text
                                            sx={{
                                                lineHeight: "12px",
                                            }}
                                            fz={12}
                                            fw={700}
                                            align="center"
                                        >
                                            {user.fullname}
                                        </Text>
                                        <Text
                                            sx={{
                                                lineHeight: "12px",
                                            }}
                                            fz={10}
                                            align="center"
                                        >
                                            {user.username}
                                        </Text>
                                    </Stack>
                                </Stack>
                                <Stack
                                    justify={"flex-start"}
                                    align="flex-start"
                                    spacing={4}
                                >
                                    {links.map((link) => (
                                        <Button
                                            key={link.text}
                                            variant="subtle"
                                            onClick={() => {
                                                closeAndRedirect(link.link);
                                            }}
                                            sx={{ width: "100%" }}
                                            styles={{
                                                inner: {
                                                    justifyContent:
                                                        "flex-start",
                                                },
                                            }}
                                            rightIcon={
                                                <ChevronRight width={14} />
                                            }
                                        >
                                            <Text
                                                align="left"
                                                sx={{ width: "100%" }}
                                            >
                                                {link.text}
                                            </Text>
                                        </Button>
                                    ))}
                                </Stack>
                            </>
                        ) : (
                            <Stack
                                sx={{
                                    justifyContent: "space-between",
                                    height: "100%",
                                }}
                                mt={16}
                            >
                                <Stack
                                    justify={"flex-start"}
                                    align="flex-start"
                                    spacing={8}
                                    px={0}
                                    sx={{ width: "100%" }}
                                >
                                    <Button
                                        fullWidth
                                        variant="subtle"
                                        onClick={() => {
                                            closeAndRedirect("/#why-we-exist");
                                        }}
                                        sx={{ width: "100%" }}
                                        styles={{
                                            inner: {
                                                justifyContent: "flex-start",
                                            },
                                        }}
                                        rightIcon={<ChevronRight width={14} />}
                                    >
                                        <Text
                                            align="center"
                                            sx={{ width: "100%" }}
                                        >
                                            Why We Exist
                                        </Text>
                                    </Button>
                                    {links.map((link) => (
                                        <Button
                                            fullWidth
                                            key={link.text}
                                            variant="subtle"
                                            onClick={() => {
                                                closeAndRedirect(link.link);
                                            }}
                                            sx={{ width: "100%" }}
                                            styles={{
                                                inner: {
                                                    justifyContent:
                                                        "flex-start",
                                                },
                                            }}
                                            rightIcon={
                                                <ChevronRight width={14} />
                                            }
                                        >
                                            <Text
                                                align="center"
                                                sx={{
                                                    width: "100%",
                                                    flexGrow: 1,
                                                }}
                                            >
                                                {link.text}
                                            </Text>
                                        </Button>
                                    ))}
                                </Stack>
                                <Stack
                                    sx={
                                        {
                                            // height: "100%",
                                        }
                                    }
                                >
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            closeAndRedirect("/login")
                                        }
                                    >
                                        Log in
                                    </Button>
                                    <Button
                                        variant="filled"
                                        onClick={() =>
                                            closeAndRedirect("/register")
                                        }
                                    >
                                        Register
                                    </Button>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                    {/* {user.userType === USER_TYPE.AGENT && (
                        <Button
                            onClick={() => closeAndRedirect("/tokens/purchase")}
                        >
                            <IconCoin
                                size={16}
                                style={{ marginRight: "4px" }}
                            />
                            <Text
                                mr={4}
                                size={20}
                                weight={500}
                                sx={{ lineHeight: "30px" }}
                            >
                                {formatTokenValue()} Tokens
                            </Text>
                        </Button>
                    )} */}
                    {!!user.jwt && (
                        <Stack
                            p={8}
                            sx={{
                                borderTop: "1px solid #ddd",
                            }}
                        >
                            <Text
                                sx={{
                                    cursor: "pointer",
                                }}
                                align="left"
                                color="red.7"
                                onClick={() => {
                                    logout();
                                    setDrawerOpen(false);
                                }}
                                fz={12}
                            >
                                Logout
                            </Text>
                        </Stack>
                    )}
                </Stack>
            </Drawer>

        </Box>
    );
}
