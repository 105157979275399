import {
    BackgroundImage,
    Box,
    Button,
    Card,
    Container,
    Grid,
    Group,
    Image,
    Stack,
    Text,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import { REGISTRATION_PIXEL, USER_TYPE } from "helpers/constants";
import { Permission, useAuth } from "hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ChevronRight,
} from "tabler-icons-react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import CustomHeader from "components/Header/Header";
import { useListingAPI } from "api/useListingAPI";
import TransparencyAboveAllElse from "pages/MainLanding/TransparencyAboveAllElse";
import FAQ from "pages/MainLanding/FAQ";
import WhyWeExist from "pages/MainLanding/WhyWeExist";
import HowItWorks from "pages/MainLanding/HowItWorks";
import SupportedBy from "pages/MainLanding/SupportedBy";
import FeaturedOn from "pages/MainLanding/FeaturedOn";
import InPartnershipWith from "pages/MainLanding/InPartnershipWith";
import WhyPropmoth from "pages/MainLanding/WhyPropmoth";
import FeaturedAgencies from "pages/MainLanding/FeaturedAgencies";
import ListingsRiver from "pages/MainLanding/ListingsRiver";
import PropRequestRiver from "pages/MainLanding/PropRequestRiver";

export default function OwnerLanding({ ReactPixel }: any) {
    const navigate = useNavigate();
    const { useGetMyPropRequests } = useListingAPI();
    const location = useLocation();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
    const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const showMobileHeader = useMediaQuery(`(max-width: 780px)`);
    const { user } = useAuth(Permission.ANYONE);
    const { data, isLoading, error, refetch } = useGetMyPropRequests(
        user?.id.toString() || "0"
    );
    const { scrollIntoView, targetRef } = useScrollIntoView<
        HTMLDivElement,
        HTMLDivElement
    >();
    const { scrollIntoView: scrollWhyWeExist, targetRef: whyWeExist } =
        useScrollIntoView<HTMLDivElement, HTMLDivElement>();
    const [stepperIndex, setStepperIndex] = useState(0);

    const sidePadding = 24;

    const ctaNavigateLink = useMemo(() => {
        if (!!user.jwt) {
            if (user.userType === USER_TYPE.LISTER) {
                if (!!data && !isLoading) {
                    if (data.length > 0) {
                        return "/profile"
                    }
                }
                return "/owner-listings/create";
            }

            if (user.userType === USER_TYPE.AGENT) {
                return "/profile?tab=leads";
            }
        }

        return "/owner-listings/create";
        // return "/register/lister";
    }, [user, data, isLoading]);

    useEffect(() => {
        if (location.hash === "#why-we-exist") {
            scrollWhyWeExist({});
        }
    }, [location]);

    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
    }, []);

    const SkewDivider = ({
        primaryColor,
        skewColor,
        skewZIndex,
        lineColor,
    }: {
        primaryColor: string;
        skewColor: string;
        skewZIndex?: number;
        lineColor?: string;
    }) => {
        return (
            <Box
                sx={{
                    background: primaryColor,
                    minHeight: 50,
                    width: "100%",
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        background: skewColor,
                        width: "100%",
                        height: 100,
                        position: "absolute",
                        left: 0,
                        top: "90%",
                        transform: `skew(0deg, -2deg) translateY(-50%)`,
                        zIndex: skewZIndex ?? 0,
                    }}
                >
                    <Container
                        size="1200px"
                        sx={{ height: "100%" }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                            }}
                        />
                    </Container>
                </Box>
                <Container
                    size="1200px"
                    // px={sidePadding}
                    sx={{ height: "50px" }}
                >
                    <Box
                        sx={{
                            height: "100%",
                        }}
                    />
                </Container>
            </Box>
        );
    };

    useEffect(() => {
        // console.log(props)
        ReactPixel.pageView(null, [REGISTRATION_PIXEL])
    }, [])


    return (
        <Box pb={16}>
            <Box
                sx={{
                    position: "relative",
                    overflow: "hidden"
                }}
                mb={32}
            >
                <Box
                    sx={{
                        height: "100%",
                        zIndex: 100,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                    }}
                >
                    <CustomHeader isLanding />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        width: "180vw",
                        height: "125vw",
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                        bottom: 0,
                        left: 0,
                        overflow: "hidden",
                        [theme.fn.smallerThan("md")]: {
                            bottom: "18%",
                        },
                        [theme.fn.smallerThan("xs")]: {
                            top: "-30%",
                            bottom: "auto",
                        },
                    }}
                >
                    <Particles
                        id="tsparticles"
                        url="./particlesjs-config.json"
                        init={particlesInit}
                        loaded={particlesLoaded}
                    />
                </Box>
                <Container
                    size="1200px"
                    px={sidePadding}
                    sx={{
                        height: "100%",
                        width: "100%",
                        minHeight: "100vh",
                        [theme.fn.smallerThan("xs")]: {
                            minHeight: "80vh",
                        },
                    }}
                >
                    <Grid>
                        <Grid.Col sm={6} xs={12}>
                            <Stack
                                sx={{
                                    // maxWidth: 500,
                                    minHeight: "100vh",
                                    [theme.fn.smallerThan("sm")]: {
                                        maxWidth: "none",
                                    },
                                    [theme.fn.smallerThan("xs")]: {
                                        minHeight: "auto",
                                        paddingTop: "30%",
                                    },
                                    justifyContent: "center",
                                }}
                                spacing={16}
                            >
                                <Stack
                                    sx={(theme) => ({
                                        height: "100%",
                                        padding: `16px`,
                                        zIndex: 100,
                                        borderRadius: 8,
                                        [theme.fn.largerThan("xs")]: {
                                            paddingLeft: 0,
                                            marginTop: "20%",
                                        },
                                    })}
                                >
                                    <Text
                                        sx={(theme) => ({
                                            fontSize: "4.5vw",
                                            [theme.fn.largerThan("xl")]: {
                                                fontSize: "4.5vw",
                                            },
                                            margin: 0,
                                            zIndex: 100,
                                            lineHeight: 1,
                                            [theme.fn.smallerThan("md")]: {
                                                fontSize: "6.5vw",
                                            },
                                            [theme.fn.smallerThan("sm")]: {
                                                fontSize: "40px",
                                            },
                                            [theme.fn.smallerThan("xs")]: {
                                                fontSize: "13vw",
                                            },
                                        })}
                                        fw="700"
                                        component="h1"
                                    >
                                        Sell and Rent
                                        <br />
                                        <Text
                                            component="span"
                                            sx={{
                                                color: "#B4E562",
                                                margin: 0,
                                                lineHeight: 1,
                                            }}
                                        >
                                            Your Property
                                        </Text>{" "}
                                        <br />
                                        Through Us!
                                    </Text>
                                    <Text
                                        sx={(theme) => ({
                                            zIndex: 100,
                                            fontSize: "20px",
                                            [theme.fn.smallerThan("md")]: {
                                                fontSize: "16px",
                                            },
                                        })}
                                        component="h3"
                                        fw={500}
                                    >
                                        Are you a landlord? Looking to sell or rent out
                                        your property without the hassle of reaching out
                                        to agents yourself? List your property here and
                                        we’ll get them to help you!
                                    </Text>
                                    <Group spacing={4}>
                                        <Button
                                            radius="xl"
                                            onClick={() =>
                                                navigate(ctaNavigateLink)
                                            }
                                            sx={(theme) => ({
                                                [theme.fn.smallerThan("xs")]: {
                                                    flex: 1,
                                                },
                                            })}
                                            rightIcon={
                                                <ChevronRight width={14} />
                                            }
                                        >
                                            <Text fz="md">Start Now </Text>
                                        </Button>
                                    </Group>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col sm={6} xs={12}>
                            <Stack
                                sx={{
                                    position: "relative",
                                    height: "100%",
                                    width: "100%",
                                }}
                                justify="center"
                            >
                                <Stack
                                    sx={{
                                        width: '100%',
                                        background: "#E4E4E4",
                                        borderRadius: 16,
                                        zIndex: 100,

                                    }}
                                    p={8}
                                    spacing={8}
                                >
                                    <img src="./owner-hero.png" />
                                </Stack>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container>
            </Box>
            <Box >
                <Container
                    size="1200px"
                    // px={sidePadding} 
                    ref={targetRef}
                >
                    <Stack pb={24}>
                        <Stack>
                            <Title align="center">Get started in 3 simple steps</Title>
                            <Grid>
                                <Grid.Col sm={4} xs={12}>
                                    <Stack justify="center" align="center">
                                        <Box
                                            p={32}
                                            sx={{ backgroundColor: "#15BC69", borderRadius: '50%' }}
                                        >
                                            <BackgroundImage
                                                src={"./owner-landing/step1.png"}
                                                w={128}
                                                h={128}
                                            />
                                        </Box>
                                        <Stack align="center" maw={"60%"}>
                                            <Text fz="lg" align="center">Renting or Selling? <br /> Tell us about your property. As much as you can! </Text>
                                        </Stack>
                                    </Stack>
                                </Grid.Col>
                                <Grid.Col sm={4} xs={12}>
                                    <Stack justify="center" align="center">
                                        <Box
                                            p={32}
                                            sx={{ backgroundColor: "#15BC69", borderRadius: '50%' }}
                                        >
                                            <BackgroundImage
                                                src={"./owner-landing/step2.png"}
                                                w={128}
                                                h={128}
                                            />
                                        </Box>
                                        <Stack align="center" maw={"60%"}>
                                            <Text fz="lg" align="center">Let us know how agents can contact you!</Text>
                                        </Stack>
                                    </Stack>
                                </Grid.Col>
                                <Grid.Col sm={4} xs={12}>
                                    <Stack justify="center" align="center">
                                        <Box
                                            p={32}
                                            sx={{ backgroundColor: "#15BC69", borderRadius: '50%' }}
                                        >
                                            <BackgroundImage
                                                src={"./owner-landing/step3.png"}
                                                w={128}
                                                h={128}
                                            />
                                        </Box>
                                        <Stack align="center" maw={"60%"}>
                                            <Text fz="lg" align="center">Sit back and relax while you wait for agents to do all the work for you!</Text>
                                        </Stack>
                                    </Stack>
                                </Grid.Col>
                            </Grid>
                            <Group position="center" >
                                <Button
                                    radius="xl"
                                    onClick={() =>
                                        navigate(ctaNavigateLink)
                                    }
                                    sx={(theme) => ({
                                        [theme.fn.smallerThan("xs")]: {
                                            flex: 1,
                                        },
                                    })}
                                    rightIcon={
                                        <ChevronRight width={14} />
                                    }
                                >
                                    <Text fz="md">Start Now </Text>
                                </Button>
                            </Group>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box sx={{ background: "#15BC69" }} py={32}>
                <Container
                    size="1200px"
                // px={sidePadding} 
                >
                    <Stack spacing={32}>
                        <Title align="center" color="white">Why should you list your property on Propmoth?</Title>
                        <Grid>
                            <Grid.Col xs={12} md={4}>
                                <Card h="100%" withBorder radius={8} shadow="md">
                                    <Stack justify="center" align="center">
                                        <Text align="center" lh={1.25} fz={64} fw="bold">500+</Text>
                                        <Text align="center" lh={1.25} fz={32} >Verified Agents Ready To Serve You!</Text>
                                        <Text align="center" lh={1.25}>We verify them 1 by 1</Text>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Card h="100%" withBorder radius={8} shadow="md">
                                    <Stack justify="center" align="center">
                                        <Text align="center" fz={64} lh={1.25} fw="bold">Every State</Text>
                                        <Text align="center" fz={32} lh={1.25} fw="bold">In Malaysia Is Covered!</Text>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Card h="100%" withBorder radius={8} shadow="md">
                                    <Stack justify="center" align="center">
                                        <Text align="center" fz={64} lh={1.25} fw="bold">FREE</Text>
                                        <Text align="center" fz={32} lh={1.25} fw="bold">For all owners and landlords to list!</Text>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Container>
            </Box>
            <Box py={32}>
                <Container
                    size="1200px"
                // px={sidePadding} 
                >
                    <Grid>
                        <Grid.Col sm={8} xs={12}>
                            <Stack pr={32}>
                                <Group spacing={8}>
                                    <Box sx={{width: 20, height: 20, background: "#15BC69", borderRadius: "50%"}}/>
                                    <Text fz={24} fw={'bold'}>Agents are always looking for property.</Text>
                                </Group>
                                <Text fz={16}>Have you ever gotten an unwanted message or a call from an agent asking if you were selling your property? </Text>
                                <Text fz={16}>If you are selling it’s great news that there are more agents in the market willing to call you to help you sell it! We’re here to make that process easier. You can just send them the link to your listing here! Why settle for one when you can have an army selling for you?</Text>
                                <Text fz={16}>If you aren’t selling, this can be a annoying. But this is the current industry practice. We’re here to change that! As the amount of owner-listed property grows, the agents will stop using the age old method of cold-calling!</Text>
                                <Group>
                                    <Button
                                        radius="xl"
                                        onClick={() =>
                                            navigate(ctaNavigateLink)
                                        }
                                        sx={(theme) => ({
                                            [theme.fn.smallerThan("xs")]: {
                                                flex: 1,
                                            },
                                        })}
                                        rightIcon={
                                            <ChevronRight width={14} />
                                        }
                                    >
                                        <Text fz="md">Start Now </Text>
                                    </Button>
                                </Group>
                            </Stack>
                        </Grid.Col>
                        <Grid.Col sm={4} xs={12}>
                            <Image src="./owner-landing/section.png" />
                        </Grid.Col>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container
                    size="1200px"
                    // px={sidePadding} 
                    ref={targetRef}
                >
                    <Stack pb={24}>
                        <FeaturedOn />
                        <SupportedBy />
                    </Stack>
                </Container>
            </Box>
        </Box >
    );
}
