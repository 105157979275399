import { Stack } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useListingAPI } from 'api/useListingAPI';
import ListingCard from 'components/PropRequest/ListingsCard';
import PropRequestCard from 'components/PropRequest/PropRequestCard';
import { useProfileController } from 'hooks/useProfile';
import React, { useMemo } from 'react';

const DashboardLatestRequests = () => {
    const { useGetSearchPropRequests } =
        useListingAPI();
    const { height, width } = useViewportSize();
    const { isPro, isBasic } = useProfileController();


    const { data, isLoading, error, refetch } = useGetSearchPropRequests({
        filters: {
            status: {
                $eq: "ACTIVE"
            }
        },
        pagination: {
            page: 1,
            pageSize: 3
        }
    });

    const results = useMemo(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            // setTotal(result.meta.pagination.pageCount)
            // setCurrentPage(result.meta.pagination.page)
            return result.data
        }

        return []
    }, [data, isLoading])

    return (
        <Stack>
            {!!results &&
                results.length > 0 &&
                results.map((propReq: any) => (
                    <PropRequestCard data={propReq} forAgents hasPermissions={isPro || isBasic} />
                ))}
        </Stack>
    );
};

export default DashboardLatestRequests;