import {
    Affix,
    Box,
    Button,
    Center,
    Drawer,
    Grid,
    Group,
    Indicator,
    Loader,
    Overlay,
    Pagination,
    Stack,
    Text,
    rem,
    useMantineTheme,
} from "@mantine/core";
import PropRequestCard from "components/PropRequest/PropRequestCard";
import { Permission, useAuth } from "hooks/useAuth";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bath, Bed, Edit, Filter, Home2, Search } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useListingAPI } from "api/useListingAPI";
import { useProfileController } from "hooks/useProfile";
import ListingFilters from "components/ListingFilters";
import LeadFilter from "components/LeadFilter";
import { IconArrowUp } from "@tabler/icons-react";
import { addUrl, defaultFilters, reducer } from "helpers/leadFilterReducer";
import { useUserAPI } from "api/userAPI";
import { notifications } from "@mantine/notifications";
import { SubscriptionBadge } from "components/Badges";
import Paywall from "./AgentDashboard/components/Paywall";

export default function SearchRequestsModified() {
    const viewport = useRef<HTMLDivElement>(null);
    const [state, dispatch] = useReducer(reducer, defaultFilters);
    const theme = useMantineTheme();
    const isTabletAndAbove = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    const navigate = useNavigate();
    const location = useLocation()
    const { useGetSearchPropRequests, useGetPropRequestInsights, useGetTags } =
        useListingAPI();
    const { useGetAgentCustomFilter } = useUserAPI()

    const {
        data: agentFilterData,
        isLoading: agentFilterIsLoading,
        error: agentFilterError,
    } = useGetAgentCustomFilter();

    const { user } = useAuth(Permission.USER_ONLY);
    const { profileData, refetchProfile, profileIsLoading, currentActiveSubscription, isPro, isBasic } = useProfileController();
    const { useUpdateAgentCustomFilter, useGetSubscription } = useUserAPI()
    const updateAgentCustomFilter = useUpdateAgentCustomFilter()

    const [filterOpen, setFilterOpen] = useState(false)
    const [filterError, setFilterError] = useState<null | string>(null)

    const [filters, setFilters] = useState({});
    const { data: subData } = useGetSubscription()

    const [total, setTotal] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const hasSetFilter = useMemo(() => {
        if (!!agentFilterData && !!agentFilterData.data) {
            const { data } = agentFilterData
            console.log({ data })

            return data.propertyType.length > 0 && (data.state.length > 0 || data.state.length > 0)
        }

        return false
    }, [agentFilterData])

    useEffect(() => {
        if (!!agentFilterData && !!agentFilterData.data) {
            const { data } = agentFilterData
            
            const payload = {
                propertyType: data.propertyType,
                transactionType: data.transactionType,
                state: data.state,
                area: data.area,
                tags: data.tags,
                subSalesOk: data.subSalesOk,
                newProjectsOnly: data.newProjectsOnly,
                isUrgent: data.isUrgent,
                ...(!!data.minPrice && { minPrice: data.minPrice }),
                ...(!!data.maxPrice && { minPrice: data.maxPrice })
            }

            dispatch({
                type: "SET_CUSTOM_FILTERS",
                payload
            })

            if (!!hasSetFilter) {
                handleFilterAndSearch(false, { ...state, ...payload })
            }

        }
    }, [agentFilterData])

    const generateUrlObj = useCallback(
        (state: any) => {
            // console.log("addurl", addUrl(state));
            return addUrl(state);
        },
        [state]
    );

    const { data, isLoading, error, refetch } = useGetSearchPropRequests({
        filters,
        pagination: {
            page: currentPage,
            pageSize: 10
        }
    });

    const results = useMemo(() => {
        let result = data as any
        if (!!data && !!result.data && !isLoading) {
            setTotal(result.meta.pagination.pageCount)
            setCurrentPage(result.meta.pagination.page)
            return result.data
        }

        return []
    }, [data, isLoading])

    const filterCount = useMemo(() => {
        let count = 0;

        Object.entries(state).forEach(([k, s]) => {
            if (k === "tags") {
                console.log("tags", state.tags)
                count += state.tags.length || 0;
                return;
            }

            if (!s) {
                return;
            }

            if (typeof s === "object") {
                if (Object.keys(s).length === 0) {
                    return;
                } else {
                    count += Object.keys(s).length;
                }
                return;
            }

            count += 1;
            return;
        });
        return count;
    }, [state]);

    const hasAccess = useMemo(() => {
        return !!currentActiveSubscription && currentActiveSubscription?.package === "PRO"
    }, [currentActiveSubscription])

    const handleFilterAndSearch = useCallback(async (isReset?: boolean, customFilter?: any) => {
        if (!hasAccess) {
            return
        }

        let urlState = customFilter ?? state
        if (!!isReset) {
            urlState = defaultFilters
        }

        if (!customFilter) {
            let sanitizedObj = {
                id: agentFilterData.data.id,
                customFilter: {}
            } as any

            if ((state.state.length === 0 && state.area.length === 0) || state.propertyType.length === 0) {
                setFilterError("Location and property type filter can not be empty")
                return
            } else {
                setFilterError(null)
            }

            const excludeThese = [
                "isUrgent",
                "newProjectsOnly",
                "subSalesOk",
            ]
            Object.entries(state).forEach(([k, v], i) => {
                if (!!v && typeof v === "object" && Object.keys(v).length > 0) {
                    sanitizedObj.customFilter[k] = v
                }
                if (!!v || !!excludeThese.includes(k)) {
                    if (k === "minPrice" || k === "maxPrice") {
                        sanitizedObj.customFilter[k] = parseInt(v as string)
                    } else {
                        sanitizedObj.customFilter[k] = v
                    }
                }
            })

            console.log(sanitizedObj)
            await updateAgentCustomFilter.mutateAsync(sanitizedObj)
            notifications.show({
                title: "Success!",
                message: "Successfully saved your filter",
                autoClose: 2000,
            });
        }

        setFilters(generateUrlObj(urlState));
    }, [agentFilterData, state, hasAccess]);

    const handleClearFilters = () => {
        dispatch({
            type: "RESET",
        });
        handleFilterAndSearch(true);
    }

    const scrollTo = useCallback(() => {
        if (viewport && viewport.current /* + other conditions */) {
            viewport.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [viewport])

    return (
        <>
            <Drawer
                opened={filterOpen}
                onClose={() => {
                    setFilterOpen(false);
                    handleFilterAndSearch();
                }}
                position="right"
            >
                <ListingFilters
                    dispatch={dispatch}
                    state={state}
                    handleClearFilters={handleClearFilters}
                    onClose={() => setFilterOpen(false)}
                />
            </Drawer>
            <Box
                sx={{}}
                style={{
                    height: '100vh',
                    width: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    whiteSpace: "nowrap",
                    position: 'relative'
                }}

            >
                <Stack
                    spacing={24}
                    sx={{ width: '100%' }}
                    p={16}
                >
                    {!isTabletAndAbove && (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleFilterAndSearch();
                            }}
                        >
                            <Grid>

                                <Grid.Col sm={12}>
                                    <Group
                                        sx={{
                                            flexWrap: "nowrap",
                                            alignItems: "flex-start",
                                            width: "100%",
                                        }}
                                        spacing={4}
                                    >
                                        <Indicator
                                            label={filterCount}
                                            disabled={filterCount === 0}
                                            size={16}
                                            sx={{ flex: 1 }}
                                            position="top-end"
                                        >
                                            <Button
                                                variant="outline"
                                                // leftIcon={}
                                                onClick={() => setFilterOpen(true)}
                                                px={8}
                                                sx={{ width: '100%' }}
                                            >
                                                <Group spacing={0}>
                                                    <Text>Filters</Text>
                                                    <Filter />
                                                </Group>
                                            </Button>
                                        </Indicator>
                                        {filterCount > 0 && (
                                            <Button
                                                variant="subtle"
                                                onClick={() => handleClearFilters()}
                                            >
                                                Clear Filters
                                            </Button>
                                        )}
                                    </Group>
                                </Grid.Col>
                            </Grid>
                        </form>
                    )}
                    <Grid>
                        {!!isTabletAndAbove && (
                            <Grid.Col xs={0} sm={12}>
                                <Stack ref={viewport} sx={{ position: 'relative' }}>
                                    {!hasAccess && (
                                        <Paywall type="PRO" />
                                    )}
                                    <LeadFilter
                                        dispatch={dispatch}
                                        state={state}
                                        onFilter={handleFilterAndSearch}
                                        filterError={filterError}
                                    />
                                </Stack>
                            </Grid.Col>
                        )}
                        <Grid.Col xs={12} sm={12}>
                            {!!hasSetFilter && (
                                <Stack spacing={16}>
                                    {!!isLoading && !!profileIsLoading && (
                                        <Center p={24}>
                                            <Loader />
                                        </Center>
                                    )}
                                    {!!results &&
                                        results.length > 0 && !!hasAccess && (
                                            <>
                                                {results.map((propReq: any) => (
                                                    <React.Fragment key={`request-${propReq.id}`}>
                                                        <PropRequestCard data={propReq} forAgents hasPermissions={isPro || isBasic} />
                                                    </React.Fragment>
                                                ))}
                                            </>
                                        )
                                    }
                                    {!!results && results.length === 0 && (
                                        <Stack align="center" spacing={8} py={32}>
                                            <Home2 size={32} />
                                            <Text fz={24}>No Data</Text>
                                        </Stack>
                                    )}
                                    <Group sx={{ width: '100%', justifyContent: 'center' }}>
                                        <Pagination total={total} onChange={setCurrentPage} />
                                    </Group>
                                </Stack>
                            )}
                        </Grid.Col>
                    </Grid>
                </Stack>

                {!!hasAccess && !!results && results.length > 0 && !!hasSetFilter && (
                    <>
                        {/* <Affix position={{ bottom: rem(20), right: rem(20) }}>
                            <Button
                                leftIcon={<IconArrowUp size="1rem" />}
                                onClick={() => scrollTo()}
                            // style={transitionStyles}
                            >
                                Scroll to top
                            </Button>
                        </Affix> */}
                        <Group sx={{ width: '100%' }} position="right">
                            <Button
                                leftIcon={<IconArrowUp size="1rem" />}
                                onClick={() => scrollTo()}
                            // style={transitionStyles}
                            >
                                Scroll to top
                            </Button>
                        </Group>
                    </>
                )}

            </Box>
        </>
    );
}
