import { Anchor, Badge, Box, Button, Card, Center, Grid, Group, ScrollArea, Select, Stack, Table, Tabs, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useGetConfigs } from 'api/configAPI';
import { useUserAPI } from 'api/userAPI';
import ListingList from 'components/ListingsList';
import OfferList from 'components/OfferList';
import { CONFIG_KEYS } from 'helpers/constants';
import { selectFilter } from 'helpers/utility';
import { useProfileController } from 'hooks/useProfile';
import React, { useEffect, useMemo, useState } from 'react';
import Paywall from './Paywall';
import DashboardStateData from './DashboardStateData';
import { useNavigate } from 'react-router-dom';
import MissingLocality from './MissingLocality';
import DashboardLatestRequests from './DashboardLatestRequests';

const DashboardComponent = () => {
    const navigate = useNavigate()
    const { profileData, refetchProfile, currentActiveSubscription } = useProfileController();
    const { useGetAgentDashboardStats } = useUserAPI()
    const {
        data: agentDashboardStats,
        isLoading: agentDashboardStatsIsLoading,
        error: agentDashboardStatsError
    } = useGetAgentDashboardStats()
    const [locationFilters, setLocationFilters] = useState<any>({
        state: "",
        area: "",
    });
    const { useAgentUpdateLocality } = useUserAPI()
    const updateLocality = useAgentUpdateLocality()
    const [states, setStates] = useState([]);
    const form = useForm({
        initialValues: {
            state: "",
            area: ""
        },
        validate: (values: any) => ({
            state: values.state.length === 0 ? "State is required" : null,
        })
    })
    const {
        data: configData,
        error: configError,
        isLoading: configIsLoading,
        refetch: refetchConfig,
    } = useGetConfigs();

    useEffect(() => {
        console.log({ agentDashboardStats })
    }, [agentDashboardStats])

    const statesAndAreas = useMemo(() => {
        if (!!configData && !configIsLoading) {
            return configData.find(
                (p: any) => p.key === CONFIG_KEYS.STATES_AND_AREAS
            ).value;
        }

        return [];
    }, [configData]);

    useEffect(() => {
        let statesData = statesAndAreas
            .map((s: any) => ({
                value: s.state,
                label: s.state,
            }))
            .filter((e: any) => !!e);

        setStates(statesData);
    }, [configData]);

    const areas = useMemo(() => {
        if (!!form.values.state) {
            const stateObj = statesAndAreas.find(
                (s: any) => s.state === form.values.state
            );
            // console.log(stateObj);
            if (!!stateObj) {
                return stateObj.areas
                    .map((a: any) => ({
                        value: a,
                        label: a,
                    }))
                    .filter((e: any) => !!e);
            }
            return [];
        }

        return [
            {
                value: "",
                label: "Please select a state first",
            },
        ];
    }, [form.values]);

    useEffect(() => {
        if (!!profileData) {
            console.log({ profileData })
            form.setValues({
                state: profileData.localityState,
                area: profileData.localityArea
            })

            setLocationFilters({
                state: profileData.localityState,
                area: profileData.localityArea ?? ""
            })
            // if (!!profileData.requestTutorialComplete && !!profileData?.localityState) {
            // }
        }
    }, [profileData])

    const handleFilter = async () => {
        const { hasErrors } = form.validate()
        if (hasErrors) {
            return
        }

        await updateLocality.mutateAsync({
            id: profileData.id,
            localityState: form.values.state,
            localityArea: form.values.area,
        })
        notifications.show({
            title: "Success!",
            message: "Successfully updated locality",
            autoClose: 2000,
        });
        refetchProfile()
    }

    const stateAreaText = useMemo(() => {
        if (!profileData?.localityState) {
            return ""
        }

        if (!!profileData.localityArea) {
            return `${profileData.localityState}, ${profileData.localityArea}`
        }

        return profileData.localityState
    }, [profileData])
    return (
        <Stack p={8} pt={16}>
            <Group>
                <Stack spacing={4}>
                    <Text fz={24} fw={700}>Dashboard</Text>
                    <Text>Check on your performance!</Text>
                </Stack>
            </Group>
            <Tabs defaultValue="overview">
                <Tabs.List>
                    <Tabs.Tab value="overview">Overview</Tabs.Tab>
                    <Tabs.Tab value="listings">My Listings</Tabs.Tab>
                    <Tabs.Tab value="offers">My Offers</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="overview" pt="xs">
                    <Stack sx={{ width: '100%' }}>

                        {/* {currentActiveSubscription.package === "UNSUBBED" && (
                        )} */}
                        <Box
                            sx={{
                                border: "1px solid #eee",
                                padding: "24px 16px ",
                                borderRadius: 8,
                                backgroundColor: "#417256",
                                color: "#fff"
                            }}
                        >
                            <Stack spacing={8}>
                                <Group position="apart">
                                    <Text fz={24} fw={700}>Your Locality</Text>
                                    <MissingLocality />
                                </Group>
                                <Group spacing={4} align='flex-end' sx={{ width: '100%' }}>
                                    <Select
                                        label="State"
                                        placeholder="Type to search..."
                                        data={states}
                                        searchable
                                        nothingFound="No options"
                                        filter={selectFilter}
                                        {...form.getInputProps(
                                            "state"
                                        )}
                                        onChange={(e) => {
                                            form.setFieldValue(
                                                "state",
                                                e || ""
                                            );
                                            form.setFieldValue(
                                                "area",
                                                ""
                                            );
                                        }}
                                        styles={{
                                            root: {
                                                // flex: 1
                                            },
                                            label: {
                                                color: "#fff"
                                            }
                                        }}
                                        dropdownPosition="bottom"
                                        zIndex={100}
                                    />
                                    <Select
                                        label="Area"
                                        placeholder={!form.values.state ? "Select state first" : "Type to search..."}
                                        data={
                                            !form.values.state
                                                ? []
                                                : areas
                                        }
                                        disabled={
                                            !form.values.state
                                        }
                                        searchable
                                        nothingFound="No options"
                                        filter={selectFilter}
                                        {...form.getInputProps(
                                            "area"
                                        )}
                                        styles={{
                                            root: {
                                                flex: 1
                                            },
                                            label: {
                                                color: "#fff"
                                            }
                                        }}
                                    />
                                    <Button
                                        onClick={() => handleFilter()}
                                        variant='outlined'
                                        style={{
                                            backgroundColor: "#417256",
                                            border: "1px solid #fff",
                                            color: "#fff"
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Group>
                                {/* <Anchor>
                                    <Group spacing={2}>
                                        <Text size="md">Add More Locations with</Text>
                                        <Badge size="sm">MothPro</Badge>
                                    </Group>
                                </Anchor> */}
                            </Stack>
                        </Box>
                        <Group>
                            <Button onClick={() => navigate("/profile?tab=leads")}>See Leads</Button>
                        </Group>
                        <Grid>
                            <Grid.Col md={4}>
                                <Card withBorder shadow='md' sx={{ height: '100%' }}>
                                    <Center sx={{ height: '100%' }}>
                                        <Stack spacing={4}>
                                            <Stack spacing={2}>
                                                <Text align='center' fz={16}>Total Leads within</Text>
                                                <Text align='center' fz={16}>{stateAreaText} this month</Text>
                                            </Stack>
                                            <Text align='center' fw={700} fz={24}>{agentDashboardStats?.totalLeadsThisMonth ?? "0"}</Text>
                                        </Stack>
                                    </Center>
                                </Card>
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <Card withBorder shadow='md' sx={{ height: '100%' }}>
                                    <Center sx={{ height: '100%' }}>
                                        <Stack spacing={4}>
                                            <Stack spacing={2}>
                                                <Text align='center' fz={16}>Total Leads within</Text>
                                                <Text align='center' fz={16}>{stateAreaText}</Text>
                                            </Stack>
                                            <Text align='center' fw={700} fz={24}>{agentDashboardStats?.totalLeadsWithinLocation ?? "0"}</Text>
                                        </Stack>
                                    </Center>
                                </Card>
                            </Grid.Col>
                            <Grid.Col md={4}>
                                <Card withBorder shadow='md' sx={{ height: '100%' }}>
                                    <Center sx={{ height: '100%' }}>
                                        <Stack spacing={4}>
                                            <Text align='center' fz={16}>Listing Views</Text>
                                            <Text align='center' fw={700} fz={24}>{agentDashboardStats?.totalOfferViews ?? "0"}</Text>
                                        </Stack>
                                    </Center>
                                </Card>
                            </Grid.Col>
                        </Grid>
                        {profileData?.localityState && (

                            <Card withBorder shadow='md'>
                                <Stack>
                                    <Text fw={"bold"} size={"xl"}>Latest Requests</Text>
                                    <ScrollArea>
                                        <DashboardLatestRequests />
                                        {/* <DashboardStateData /> */}
                                    </ScrollArea>
                                    <Button onClick={() => navigate("/profile?tab=leads")}>See all leads</Button>
                                </Stack>
                            </Card>
                        )}
                    </Stack>

                </Tabs.Panel>

                <Tabs.Panel value="listings" pt="xs">
                    <Box
                        sx={{
                            position: "relative"
                        }}
                    >
                        {/* {!hasAccess && (
                            <Paywall type="BASIC" />
                        )} */}
                        <ListingList />
                    </Box>
                </Tabs.Panel>

                <Tabs.Panel value="offers" pt="xs">
                    <Box
                        sx={{
                            position: "relative"
                        }}
                    >
                        {/* {!hasAccess && (
                            <Paywall type="BASIC" />
                        )} */}
                        <OfferList />
                    </Box>
                </Tabs.Panel>
            </Tabs>
        </Stack>
    );
};

export default DashboardComponent;